import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import './Header.css';

function Header() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const jwtToken = localStorage.getItem("jwtToken");
  const navigate = useNavigate();

  useEffect(() => {
    if (jwtToken) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [jwtToken]);

  const handleLogout = () => {
    localStorage.removeItem("jwtToken");
    setIsAuthenticated(false);
    navigate("/login");
  };

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <header className="header">
      <div className="header-content">
        <Link className="logo-font" to="/">
        <img className="logo-icon" src="/images/gymbro-icon.png" alt="Gymbro logo" />
        
        <h1>Gymbro</h1>
        </Link>
        <nav className={`nav ${isMenuOpen ? "open" : ""}`}>
          {isAuthenticated && (
            <>
              <Link to="/my-dashboard">Dashboard</Link>
              <Link to="/my-workouts">Økter</Link>
              <Link to="/user-profile">Profil</Link>
              <button className="blue-btn" onClick={handleLogout}>
                Logout
              </button>
            </>
          )}
          {!isAuthenticated && (
            <Link className="blue-btn log-out-btn" to="/login">
              Login
            </Link>
          )}
        </nav>
        <button className="hamburger" onClick={toggleMenu}>
          ☰
        </button>
      </div>
    </header>
  );
}

export default Header;
