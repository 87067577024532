import React, { useState, useEffect } from "react";
import SetDeleteButton from "./../../api/DeleteSet";
import axios from "axios";
import ExerciseList from "./../../values/Exercises";
import SetListTemplate from "./SetTemplateOne";

const SetList = ({ workoutId }) => {
  const [workoutData, setWorkoutData] = useState(null);
  const [newSets, setNewSets] = useState({});

  useEffect(() => {
    fetchWorkoutData();
  }, [workoutId]);

  const fetchWorkoutData = async () => {
    try {
      const response = await axios.post(
        `https://gymbro.no/backend/wp-json/gymbro/v1/current-workout/${workoutId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          },
        }
      );

      if (response.status === 200) {
        setWorkoutData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const findExerciseIcon = (exerciseName) => {
    const exercise = ExerciseList.find((item) => item.Name === exerciseName);
    return exercise ? exercise.Icon : null;
  };

  const findSetTemplate = (exerciseName) => {
    const exercise = ExerciseList.find((item) => item.Name === exerciseName);
    return exercise ? exercise.Type : null;
  };

  const handleAddSetClick = (exerciseType) => {
    const template = findSetTemplate(exerciseType);

    setNewSets((prev) => ({
      ...prev,
      [exerciseType]: {
        template,
        weight: "",
        weightUnit: "kg",
        repetitions: "",
        time: "",
        timeUnit: "min",
        distance: "",
        distanceUnit: "km",
        height: "",
        heightUnit: "moh",
        laps: "",
        tiredness: "5",
      },
    }));
  };

  const handleNewSetChange = (exerciseType, field, value) => {
    setNewSets((prev) => ({
      ...prev,
      [exerciseType]: { ...prev[exerciseType], [field]: value },
    }));
  };

  const handleSaveSet = async (exerciseType) => {
    const newSet = newSets[exerciseType];
    if (!newSet) return;

    const setToSave = {
      set_workout_id: workoutId,
      set_workout_type: exerciseType,
      set_weight: newSet.weight ? parseInt(newSet.weight) : null,
      set_weight_unit: newSet.weightUnit,
      set_repetitions: newSet.repetitions ? parseInt(newSet.repetitions) : null,
      set_time: newSet.time || null,
      set_time_unit: newSet.timeUnit,
      set_distance: newSet.distance ? parseInt(newSet.distance) : null,
      set_distance_unit: newSet.distanceUnit,
      set_height: newSet.height ? parseInt(newSet.height) : null,
      set_height_unit: newSet.heightUnit,
      set_tiredness: newSet.tiredness ? parseInt(newSet.tiredness) : null,
      set_laps: newSet.laps ? parseInt(newSet.laps) : null,
    };

    try {
      const response = await axios.post(
        `https://gymbro.no/backend/wp-json/gymbro/v1/add-set/${workoutId}`,
        setToSave,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Set saved successfully");
        fetchWorkoutData();
        setNewSets((prev) => ({
          ...prev,
          [exerciseType]: null,
        }));
      } else {
        console.error("Error saving set");
      }
    } catch (error) {
      console.error("Request error:", error);
    }
  };

  if (workoutData === null) {
    return null;
  }

  const setsByType = {};
  workoutData.sets.forEach((set) => {
    if (!setsByType[set.gb_set_workout_type]) {
      setsByType[set.gb_set_workout_type] = [];
    }
    setsByType[set.gb_set_workout_type].push(set);
  });

  	return (
    	<div className="list-row-container">
      		{Object.keys(setsByType).map((setType) => (
        		<div key={setType} className="list-row-parent">
					<div className="list-row-header">
						<div className="list-row-icon">
							<img
								src={`https://gymbro.no/assets/icons/exercises/${findExerciseIcon(setType)}`}
								alt="Exercise Icon"
							/>
							
						</div>
						<h3 className="list-row-type-title">{setType}</h3>
          			</div>

					<div className="list-row-body">
					
						{setsByType[setType].map((set) => {

							const setTemplate = findSetTemplate(set.gb_set_workout_type);

							return (
								<div className="list-row" key={set.id}>
									{setTemplate && <SetListTemplate exerciseType={set} template={setTemplate} />}
									<div className="list-row-set-btns">
										<SetDeleteButton setId={set.id} onSetDeleted={fetchWorkoutData} />
									</div>
								</div>
							);
						})}


            {/* Inline New Set Form (Using Set Template) */}
            {newSets[setType] && (
              <div className="list-row new-set-row new-set-inline-form">
                <div className="new-set-inline-form-section">
                {newSets[setType].template === 1 && (
                  <>
                    <label>Kg: </label>
                    <input
                      type="number"
                      value={newSets[setType].weight}
                      onChange={(e) => handleNewSetChange(setType, "weight", e.target.value)}
                    />
                    <label>Reps:</label>
                    <input
                      type="number"
                      value={newSets[setType].repetitions}
                      onChange={(e) => handleNewSetChange(setType, "repetitions", e.target.value)}
                    />
                  </>
                )}

                {newSets[setType].template === 2 && (
                  <>
                    <label>Runder:</label>
                    <input
                      type="number"
                      value={newSets[setType].repetitions}
                      onChange={(e) => handleNewSetChange(setType, "repetitions", e.target.value)}
                    />
                    <label>Tid:</label>
                    <input
                      type="time"
                      value={newSets[setType].time}
                      onChange={(e) => handleNewSetChange(setType, "time", e.target.value)}
                    />
                    <label>Km:</label>
                    <input
                      type="number"
                      value={newSets[setType].distance}
                      onChange={(e) => handleNewSetChange(setType, "distance", e.target.value)}
                    />
                  </>
                )}

                {newSets[setType].template === 6 && (
                  <>
                    <label>Runder:</label>
                    <input
                      type="number"
                      value={newSets[setType].repetitions}
                      onChange={(e) => handleNewSetChange(setType, "repetitions", e.target.value)}
                    />
                    <label>Høydemeter:</label>
                    <input
                      type="number"
                      value={newSets[setType].height}
                      onChange={(e) => handleNewSetChange(setType, "height", e.target.value)}
                    />
                  </>
                )}
                </div>
                <div className="new-set-inline-form-section">

                <label>Trøtthet:</label>
                <input
                  type="range"
                  min="1"
                  max="10"
                  value={newSets[setType].tiredness}
                  onChange={(e) => handleNewSetChange(setType, "tiredness", e.target.value)}
                />

                <button className="save-btn small-btn" onClick={() => handleSaveSet(setType)}>
                  Lagre
                </button>
                <button className="cancel-btn small-btn" onClick={() => handleAddSetClick(setType)}>
                  X
                </button>
                </div>
              </div>
            )}
                        <button className="blue-btn small-btn" onClick={() => handleAddSetClick(setType)}>
              + Nytt sett
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SetList;
