import React from "react";

const EquipmentArray =  [
    {
        id: 1,
        name: "Benk",
        exercises: [
        ],
        type: "strength",
        dfetchId: "9-warm-starfish"
    },
    {
        id: 2,
        name: "Manualer",
        exercises: [
        ],
        type: "strength",
        dfetchId: "46-strong-squids"
    },
    {
        id: 3,
        name: "Kettlebells",
        exercises: [
        ],
        type: "strength",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 4,
        name: "Kabel",
        exercises: [
        ],
        type: "strength",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 5,
        name: "Tredemølle",
        exercises: [
        ],
        type: "running",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 6,
        name: "Løpebane",
        exercises: [
        ],
        type: "running",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 7,
        name: "Sykkel",
        exercises: [
        ],
        type: "running",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 8,
        name: "Romaskin",
        exercises: [
        ],
        type: "running",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 9,
        name: "Vektstang",
        exercises: [
        ],
        type: "strength",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 10,
        name: "Spinning",
        exercises: [
        ],
        type: "running",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 11,
        name: "Ellipsemaskin",
        exercises: [
        ],
        type: "running",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 12,
        name: "Stepmaskin",
        exercises: [
        ],
        type: "running",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 13,
        name: "Ski",
        exercises: [
        ],
        type: "running",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 14,
        name: "Rulleski",
        exercises: [
        ],
        type: "running",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 15,
        name: "Skøyter",
        exercises: [
        ],
        type: "running",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 16,
        name: "Staver",
        exercises: [
        ],
        type: "running",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 17,
        name: "Svømming",
        exercises: [
        ],
        type: "running",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 18,
        name: "Tåhev apparat",
        exercises: [
            {
                id: 44,
            },
            {
                id: 43,
            }
        ],
        type: "strength",
        dfetchId: "47-strong-squids"
    },
];

export default EquipmentArray;