import React, { useState, useEffect } from 'react';
import QrReader from 'react-qr-scanner';

const QRScanner = ({ onScan }) => {
  const [scanning, setScanning] = useState(true);
  const [videoConstraints, setVideoConstraints] = useState({});

  useEffect(() => {
    // Check if the device is mobile
    const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

    setVideoConstraints({
      video: {
        facingMode: isMobile ? { exact: "environment" } : "user", // Rear camera on mobile, default on desktop
      }
    });
  }, []);

  const handleScan = (data) => {
    if (data) {
      setScanning(false); // Stop scanning after a successful scan
      onScan(data);
    }
  };

  const handleError = (err) => {
    console.error("QR Scanner Error:", err);
  };

  return (
    <div>
      {scanning ? (
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
          constraints={videoConstraints} // Dynamically applied constraints
        />
      ) : (
        <p>QR Code Scanned!</p>
      )}
    </div>
  );
};

export default QRScanner;
