import React from "react";

const ExerciseForm = ({ exerciseType, template }) => {
  const { Type } = exerciseType;

  // Define separate templates for each type
  const renderType1 = () => {
    // Template for Type 1
    return (
        <div className="list-row-details">
          
          
            <div className="set-template-item">
                <p className="set-template-one">{exerciseType.gb_set_weight} kg</p>
            </div>
            <div className="set-template-item-x">
                <p className="set-template-one">x</p>
            </div>
            <div className="set-template-item">
                <p className="set-template-one">{exerciseType.gb_set_repetitions} reps</p>
            </div>
        </div>
    );
  };

  const renderType2 = () => {
    // Template for Type 2
    return (
      <div className="list-row-details">
            <div className="set-template-item">
        <p className="set-template-one">{exerciseType.gb_set_repetitions} x {exerciseType.gb_set_distance} {exerciseType.gb_set_distance_unit}</p>
      </div>
      <div className="set-template-item">
                <p className="set-template-one">{exerciseType.gb_set_time} {exerciseType.gb_set_time_unit}</p>
            </div>
            </div>
    );
  };

  const renderType3 = () => {
    // Template for Type 3
    return (
        <div>
        <p className="set-template-one">{exerciseType.gb_set_repetitions} x {exerciseType.gb_set_weight} kg</p>
      </div>
    );
  };

  const renderType4 = () => {
    // Template for Type 4
    return (
        <div>
        <p className="set-template-one">{exerciseType.gb_set_repetitions} x {exerciseType.gb_set_weight} kg</p>
      </div>
    );
  };

    const renderType5 = () => {
    // Template for Type 5
    return (
        <div>
        <p className="set-template-one">{exerciseType.gb_set_repetitions} x {exerciseType.gb_set_weight} kg</p>
      </div>
    );
    };

    const renderType6 = () => {
    // Template for Type 6
    return (
        <div>
        <p className="set-template-one">{exerciseType.gb_set_repetitions} x {exerciseType.gb_set_weight} kg</p>
      </div>
    );
    };

    const renderType7 = () => {
    // Template for Type 7
    return (
        <div>
        <p className="set-template-one">{exerciseType.gb_set_repetitions} x {exerciseType.gb_set_weight} kg</p>
      </div>
    );
    };

    const renderType8 = () => {
    // Template for Type 8
    return (
        <div>
        <p className="set-template-one">{exerciseType.gb_set_repetitions} x {exerciseType.gb_set_weight} kg</p>
      </div>
    );
    };

  // Add more templates for other types as needed

  // Render the appropriate template based on the Type
  switch (template) {
    case 1:
      return renderType1();
    case 2:
      return renderType2();
    case 3:
        return renderType3();
    case 4:
        return renderType4();
    case 5:
        return renderType5();
    case 6:
        return renderType6();
    case 7:
        return renderType7();
    case 8:
        return renderType8();

    default:
      return <div>Unsupported exercise type</div>;
  }
};

export default ExerciseForm;
