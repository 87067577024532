const ExerciseList = [
    {   "id": 1,
        "Name": "Flyes (kabel maskin)",
        "Icon": "strength-white.png",
        "Muscles": "Skuldre, Bryst, Triceps",
        "Equipment": "kabelmaskin",
        "Type": 1,
        "data": [
            {
                "type": "weight",
                "unit": "kg"
            },
            {
                "type": "repetitions",
                "unit": "reps"
            }
        
        ],
        "options": false
    },
    {
        "id": 2,
        "Name": "Markløft",
        "Icon": "deadlift-white.png",
        "Muscles": "Rygg, lår, rumpe",
        "Equipment": "Vektstang",
        "Type": 1,
        "data": [
            {
                "type": "weight",
                "unit": "kg",
                "name": "Vekt"

            },
            {
                "type": "repetitions",
                "unit": "reps",
                "name": "Repetisjoner"
            }
        
        ],
        "options": [
          {
            "type": "Benstilling",
            "options": [
              "normalt",
              "smal",
              "bred"
            ]

          }
        ]
    },
    {
        "id": 3,
        "Name": "Kettlebell Sving",
        "Icon": "strength-white.png",
        "Muscles": "Rygg, skuldre, lår",
        "Equipment": "Kettlebell",
        "Type": 1,
        "data": [
            {
                "type": "weight",
                "unit": "kg",
                "name": "Vekt"

            },
            {
                "type": "repetitions",
                "unit": "reps",
                "name": "Repetisjoner"
            }
        
        ],
        "options": false
    },
    {
        "id": 4,
        "Name": "Planken",
        "Icon": "plank-white.png",
        "Muscles": "Kjerne, skuldre",
        "Equipment": "Ingen",
        "Type": 8,
        "data": [
            {
                "type": "time",
                "unit": "min, sek",
                "name": "Tid"
            }
        
        ],
        "options": false
    },
    {
        "id": 5,
      "Name": "Roing på romaskin",
      "Icon": "row-machine-white.png",
      "Muscles": "Rygg, armer, bein",
      "Equipment": "Romaskin",
      "Type": 3,
      "data": [
          {
              "type": "distance",
              "unit": "m",
              "name": "Distanse"
          },
          {
              "type": "time",
              "unit": "min, sek",
              "name": "Tid"
          }
      
      ],
      "options": false
    },
    {
        "id": 6,
      "Name": "Dips",
        "Icon": "strength-white.png",
      "Muscles": "Triceps, bryst",
      "Equipment": "Dipstativ",
      "Type": 1,
      "data": [
          {
              "type": "repetitions",
              "unit": "reps",
              "name": "Repetisjoner"
          }
      
      ],
      "options": [
        {
          "type": "Grep",
          "options": [
            "normalt",
            "smalt",
            "bredt"
          ]

        }
      ]
    },
    {
        "id": 7,
      "Name": "Biceps curl (apparat)",
      "Icon": "strength-white.png",
      "Muscles": "armer, biceps",
      "Equipment": "Curl apparat",
      "Type": 1,
      "data": [
          {
              "type": "weight",
              "unit": "kg",
              "name": "Vekt"

          },
          {
              "type": "repetitions",
              "unit": "reps",
              "name": "Repetisjoner"
          }
      
      ],
      "options": [
        {
          "type": "Grep",
          "options": [
            "normalt",
            "smalt",
            "bredt"
          ]

        }
      ]
    },
    {
        "id": 8,
      "Name": "Burpees",
      "Icon": "strength-white.png",
      "Muscles": "Hele kroppen",
      "Equipment": "Ingen",
      "Type": 1,
      "data": [
          {
              "type": "repetitions",
              "unit": "reps",
              "name": "Repetisjoner"
          }
      
      ],
      "options": false
    },
    {
        "id": 9,
      "Name": "Hengende beinhev",
        "Icon": "strength-white.png",
      "Muscles": "Mage, underkropp",
      "Equipment": "Pull-up bar",
      "Type": 1,
      "data": [
          {
              "type": "repetitions",
              "unit": "reps",
              "name": "Repetisjoner"
          }
      
      ],
      "options": false
    },
    {
        "id": 10,
      "Name": "Sykling",
      "Icon": "cycling-white.png",
      "Muscles": "Bein, kardiovaskulært",
      "Equipment": "Sykkel",
      "Type": 2,
      "data": [
          {
              "type": "distance",
              "unit": "km",
              "name": "Distanse"
          },
          {
              "type": "time",
              "unit": "min, sek",
              "name": "Tid"
          }
      
      ],
      "options": false
    },
    {
        "id": 11,
      "Name": "Skulderpress",
      "Icon": "strength-white.png",
      "Muscles": "Skuldre, triceps",
      "Equipment": "Vektstang",
      "Type": 1,
      "data": [
          {
              "type": "weight",
              "unit": "kg",
              "name": "Vekt"

          },
          {
              "type": "repetitions",
              "unit": "reps",
              "name": "Repetisjoner"
          }
      
      ],
      "options": false
    },
    {
        "id": 12,
      "Name": "Skulderpress med manualer",
      "Icon": "strength-white.png",
      "Muscles": "Skuldre, triceps",
      "Equipment": "Manualer",
      "Type": 1,
      "data": [
          {
              "type": "weight",
              "unit": "kg",
              "name": "Vekt"

          },
          {
              "type": "repetitions",
              "unit": "reps",
              "name": "Repetisjoner"
          }
      
      ],
      "options": false
    },
    {
        "id": 13,
      "Name": "Armhevinger",
      "Icon": "push-ups-white.png",
      "Muscles": "Bryst, triceps, skuldre",
      "Equipment": "Ingen",
      "Type": 1,
      "data": [
          {
              "type": "repetitions",
              "unit": "reps",
              "name": "Repetisjoner"
          }
      
      ],
      "options": [
        {
          "type": "Grep",
          "options": [
            "normalt",
            "smalt",
            "bredt"
          ]

        }
      ]
    },
    {
        "id": 14,
      "Name": "Hip Thrusts",
      "Icon": "strength-white.png",
      "Muscles": "Gluteus, hamstrings",
      "Equipment": "Benk",
      "Type": 1,
      "data": [
          {
              "type": "weight",
              "unit": "kg",
              "name": "Vekt"

          },
          {
              "type": "repetitions",
              "unit": "reps",
              "name": "Repetisjoner"
          }
      
      ],
      "options": false
    },
    {
        "id": 15,
      "Name": "Hoppetau",
      "Icon": "jump-rope-white.png",
      "Muscles": "Ben, kardiovaskulært",
      "Equipment": "Hoppetau",
      "Type": 4,
      "data": [
          {
              "type": "repetitions",
              "unit": "reps",
              "name": "Repetisjoner"
          },
          {
              "type": "time",
              "unit": "min, sek",
              "name": "Tid"
          }
      
      ],
      "options": false
    },
    {
        "id": 16,
      "Name": "Biceps curl (manualer)",
      "Icon": "curls-with-dumbbells-white.png",
      "Muscles": "Biceps",
      "Equipment": "manualer",
      "Type": 1,
      "data": [
          {
              "type": "weight",
              "unit": "kg",
              "name": "Vekt"

          },
          {
              "type": "repetitions",
              "unit": "reps",
              "name": "Repetisjoner"
          }
      
      ],
      "options": false
    },
    {
        "id": 17,
        "Name": "Biceps curl (stang)",
        "Icon": "curls-with-dumbbells-white.png",
        "Muscles": "Biceps",
        "Equipment": "Vektstang",
        "Type": 1,
        "data": [
            {
                "type": "weight",
                "unit": "kg",
                "name": "Vekt"
  
            },
            {
                "type": "repetitions",
                "unit": "reps",
                "name": "Repetisjoner"
            }
        
        ],
        "options": false
      },
    {
        "id": 18,
      "Name": "Sprint",
      "Icon": "running-white.png",
      "Muscles": "Ben, kardiovaskulært",
      "Equipment": "Ingen",
      "Type": 2,
      "data": [
          {
              "type": "distance",
              "unit": "m",
              "name": "Distanse"
          },
          {
              "type": "time",
              "unit": "min, sek",
              "name": "Tid"
          }
      
      ],
      "options": false
    },
    {
        "id": 19,
      "Name": "Spensthopp",
      "Icon": "strength-white.png",
      "Muscles": "Bein, kardiovaskulært",
      "Equipment": "Plyo-boks",
      "Type": 6,
      "data": [
          {
              "type": "height",
              "unit": "cm",
              "name": "Høyde"
          },
          {
              "type": "repetitions",
              "unit": "reps",
              "name": "Repetisjoner"
          }
      
      ],
      "options": false
    },
    {
        "id": 20,
      "Name": "Fjelltur",
      "Icon": "hiking-white.png",
      "Muscles": "Kjerne, kardiovaskulært",
      "Equipment": "Ingen",
      "Type": 7,
      "data": [
          {
              "type": "height",
              "unit": "m",
              "name": "Høyde"
          },
          {
              "type": "time",
              "unit": "min, sek",
              "name": "Tid"
          },
          {
              "type": "distance",
              "unit": "km",
              "name": "Distanse"
          }
      
      ],
      "options": false
    },
    {
        "id": 21,
      "Name": "Sittende roing",
      "Icon": "row-machine-white.png",
      "Muscles": "Rygg, biceps",
      "Equipment": "Ro maskin",
      "Type": 1,
      "data": [
          {
              "type": "weight",
              "unit": "kg",
              "name": "Vekt"

          },
          {
              "type": "repetitions",
              "unit": "reps",
              "name": "Repetisjoner"
          }
      
      ],
      "options": false
    },
    {
        "id": 22,
      "Name": "Crunches",
      "Icon": "strength-white.png",
      "Muscles": "Mage",
      "Equipment": "Ingen",
      "Type": 1,
      "data": [
          {
              "type": "repetitions",
              "unit": "reps",
              "name": "Repetisjoner"
          }
      
      ],
      "options": false
    },
    {
        "id": 23,
      "Name": "Skulderrotasjon med kabel",
      "Icon": "strength-white.png",
      "Muscles": "Skuldre",
      "Equipment": "Kabelmaskin",
      "Type": 1,
      "data": [
          {
              "type": "weight",
              "unit": "kg",
              "name": "Vekt"

          },
          {
              "type": "repetitions",
              "unit": "reps",
              "name": "Repetisjoner"
          }
      
      ],
      "options": false
    },
    {
        "id": 24,
      "Name": "Sprint på tredemølle",
      "Icon": "treadmill-white.png",
      "Muscles": "Bein, kardiovaskulært",
      "Equipment": "Tredemølle",
      "Type": 3,
      "data": [
          {
              "type": "distance",
              "unit": "m",
              "name": "Distanse"
          },
          {
              "type": "time",
              "unit": "min, sek",
              "name": "Tid"
          }
      
      ],
      "options": false
    },
    {
        "id": 25,
      "Name": "Pull-ups",
      "Icon": "pullups-white.png",
      "Muscles": "Rygg, biceps",
      "Equipment": "Ingen",
      "Type": 1,
      "data": [
          {
              "type": "repetitions",
              "unit": "reps",
              "name": "Repetisjoner"
          }
      
      ],
      "options": [
        {
          "type": "Grep",
          "options": [
            "normalt",
            "smalt",
            "bredt"
          ]

        }
      ]
    },
    {
        "id": 26,
      "Name": "Jump Squats",
      "Icon": "squats-white.png",
      "Muscles": "Lår, rumpe",
      "Equipment": "Ingen",
      "Type": 4,
      "data": [
          {
              "type": "repetitions",
              "unit": "reps",
              "name": "Repetisjoner"
          },
          {
              "type": "height",
              "unit": "cm",
              "name": "Høyde"
          }
      
      ],
      "options": false
    },
    {
        "id": 27,
      "Name": "Armhevinger på knærne",
      "Icon": "strength-white.png",
      "Muscles": "Bryst, triceps",
      "Equipment": "Ingen",
      "Type": 1 ,
      "data": [
          {
              "type": "repetitions",
              "unit": "reps",
              "name": "Repetisjoner"

          }
      
      ],
      "options": [
        {
          "type": "Grep",
          "options": [
            "normalt",
            "smalt",
            "bredt"
          ]

        }
      ]
    },
    {
        "id": 28,
      "Name": "Step-ups",
      "Icon": "strength-white.png",
      "Muscles": "Lår, rumpe",
      "Equipment": "Ingen",
      "Type": 1,
      "data": [
          {
              "type": "repetitions",
              "unit": "reps",
              "name": "Repetisjoner"

          }
      
      ],
      "options": false
    },
    {
        "id": 29,
      "Name": "Liggende benhev",
      "Icon": "strength-white.png",
      "Muscles": "Mage",
      "Equipment": "Ingen",
      "Type": 1,
      "data": [
          {
              "type": "repetitions",
              "unit": "reps",
              "name": "Repetisjoner"

          }
      
      ],
      "options": false
    },
    {
        "id": 30,
      "Name": "Medisinball kast",
      "Icon": "strength-white.png",
      "Muscles": "Hele kroppen",
      "Equipment": "Medisinball",
      "Type": 1,
      "data": [
          {
              "type": "height",
              "unit": "cm",
              "name": "Høyde"

          },
          {
              "type": "weight",
              "unit": "kg",
              "name": "Vekt"

          }
      
      ],
      "options": false
    },
    {
        "id": 31,
      "Name": "Hoppetau med doble under",
      "Icon": "jump-rope-white.png",
      "Muscles": "Kardiovaskulært",
      "Equipment": "Hoppetau",
      "Type": 4,
      "data": [
          {
              "type": "time",
              "unit": "min, sek",
              "name": "Tid"

          }
      
      ],
      "options": false
    },
    {
        "id": 32,
        "Name": "Benkpress",
        "Icon": "benchpress-white.png",
        "Muscles": "Bryst, skuldre, triceps",
        "Equipment": "Benk",
        "Type": 1,
        "data": [
            {
                "type": "weight",
                "unit": "kg",
                "name": "Vekt"

            },
            {
                "type": "repetitions",
                "unit": "reps",
                "name": "Repetisjoner"
            }
        
        ],
        "options": false
    },
    {
        "id": 33,
        "Name": "Brystpress med manualer (flat benk)",
        "Icon": "strength-white.png",
        "Muscles": "Bryst, skuldre, triceps",
        "Equipment": "Benk",
        "Type": 1,
        "data": [
            {
                "type": "weight",
                "unit": "kg",
                "name": "Vekt"

            },
            {
                "type": "repetitions",
                "unit": "reps",
                "name": "Repetisjoner"
            }
        
        ],
        "options": [
          {
            "type": "Benkstilling",
            "options": [
              "Flat",
              "Skrå"
            ]

          }
     
        ]

    },
    {
        "id": 34,
        "Name": "Pulldown",
        "Icon": "strength-white.png",
        "Muscles": "Rygg, biceps",
        "Equipment": "Pulldown apparat",
        "Type": 1,
        "data": [
            {
                "type": "weight",
                "unit": "kg",
                "name": "Vekt"

            },
            {
                "type": "repetitions",
                "unit": "reps",
                "name": "Repetisjoner"
            }
        
        ],
        "options": [
          {
            "type": "Grep",
            "options": [
              "normalt",
              "smalt",
              "bredt"
            ]

          }
        ]
    },
    {
        "id": 35,
        "Name": "Sidehev med manualer",
        "Icon": "strength-white.png",
        "Muscles": "Skuldre",
        "Equipment": "Manualer",
        "Type": 1,
        "data": [
            {
                "type": "weight",
                "unit": "kg",
                "name": "Vekt"

            },
            {
                "type": "repetitions",
                "unit": "reps",
                "name": "Repetisjoner"
            }
        
        ],
        "options": false
    },
    {
        "id": 36,
        "Name": "Knebøy",
        "Icon": "squats-white.png",
        "Muscles": "Lår, rumpe",
        "Equipment": "Ingen",
        "Type": 1,
        "data": [
            {
                "type": "weight",
                "unit": "kg",
                "name": "Vekt"

            },
            {
                "type": "repetitions",
                "unit": "reps",
                "name": "Repetisjoner"
            }
        
        ],
        "options": false
    },
    {
        "id": 37,
        "Name": "Knebøy med vektstang",
        "Icon": "squats-white.png",
        "Muscles": "Lår, rumpe",
        "Equipment": "Vektstang",
        "Type": 1,
        "data": [
            {
                "type": "weight",
                "unit": "kg",
                "name": "Vekt"

            },
            {
                "type": "repetitions",
                "unit": "reps",
                "name": "Repetisjoner"
            }
        
        ],
        "options": false
    },
    {
        "id": 38,
        "Name": "Fronthev med manualer",
        "Icon": "strength-white.png",
        "Muscles": "Skuldre",
        "Equipment": "Manualer",
        "Type": 1,
        "data": [
            {
                "type": "weight",
                "unit": "kg",
                "name": "Vekt"

            },
            {
                "type": "repetitions",
                "unit": "reps",
                "name": "Repetisjoner"
            }
        
        ],
        "options": false
    },
    {
        "id": 39,
        "Name": "Jogging",
        "Icon": "running-white.png",
        "Muscles": "Bein",
        "Equipment": "Ingen",
        "Type": 2,
        "data": [
            {
                "type": "distance",
                "unit": "km",
                "name": "Distanse"
            },
            {
                "type": "time",
                "unit": "min, sek",
                "name": "Tid"
            }
        
        ],
        "options": false
    },
    {
        "id": 40,
        "Name": "Løping",
        "Icon": "running-white.png",
        "Muscles": "Bein, legger, lår",
        "Equipment": "Ingen",
        "Type": 2,
        "data": [
            {
                "type": "distance",
                "unit": "km",
                "name": "Distanse"
            },
            {
                "type": "time",
                "unit": "min, sek",
                "name": "Tid"
            }
        
        ],
        "options": false
    },
    {
        "id": 41,
        "Name": "Pullover",
        "Icon": "strength-white.png",
        "Muscles": "skuldre, bryst, triceps",
        "Equipment": "Ingen",
        "Type": 1,
        "data": [
            {
                "type": "weight",
                "unit": "kg"
            },
            {
                "type": "repetitions",
                "unit": "reps"
            }
        
        ],
        "options": false
    },
    {
        "id": 42,
        "Name": "Lårpress",
        "Icon": "strength-white.png",
        "Muscles": "Lår, rumpe",
        "Equipment": "Lårpress apparat",
        "Type": 1,
        "data": [
            {
                "type": "weight",
                "unit": "kg",
                "name": "Vekt"

            },
            {
                "type": "repetitions",
                "unit": "reps",
                "name": "Repetisjoner"
            }
        
        ],
        "options": [
            {
                "type": "Benstilling",
                "options": [
                "normal",
                "smal",
                "bred"
                ]
    
            }
            ]
      
    },
    {
        "id": 43,
        "Name": "Tåhev",
        "Icon": "strength-white.png",
        "Muscles": "Legger",
        "Equipment": "Ingen",
        "Type": 1,
        "data": [
            {
                "type": "weight",
                "unit": "kg"
            },
            {
                "type": "repetitions",
                "unit": "reps"
            }
        
        ],
        "options": false
    },
    {
        "id": 44,
        "Name": "Tåhev (apparat)",
        "Icon": "strength-white.png",
        "Muscles": "Legger",
        "Equipment": "Tåhev apparat",
        "Type": 1,
        "data": [
            {
                "type": "weight",
                "unit": "kg"
            },
            {
                "type": "repetitions",
                "unit": "reps"
            }
        
        ],
        "options": false
    }
  ];  

  export default ExerciseList;