import React from 'react';

const ExerciseSelectionModal = ({ exercises, onSelect, onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Velg øvelse</h2>
        <ul>
          {exercises.map((exercise) => (
            <li key={exercise.id} onClick={() => onSelect(exercise)} className="exercise-item">
              {exercise.Name}
            </li>
          ))}
        </ul>
        <button className="cancel-btn" onClick={onClose}>Avbryt</button>
      </div>
    </div>
  );
};

export default ExerciseSelectionModal;